import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import PasswordStrengthBar from "react-password-strength-bar";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";
import LocalizedLink from "components/LocalizedLink";

function ResetForm(props) {
  const { t } = useTranslation();
  const { onRequestToken, data, onChange } = props;
  const [emailFocus, setEmailFocus] = useState("");

  return (
    <Form role="form" onSubmit={onRequestToken}>
      <div className="text-center text-muted mb-4">
        <small>{t("auth.reset.header.line1")}</small>
        <br />
        <small>{t("auth.reset.header.line2")}</small>
      </div>
      <FormGroup className={"mb-3 " + emailFocus}>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("users.fields.email")}
            type="email"
            onFocus={() => setEmailFocus("focused")}
            onBlur={() => setEmailFocus("")}
            name="email"
            value={data?.email}
            onChange={onChange}
          ></Input>
        </InputGroup>
      </FormGroup>
      <div className="text-center">
        <Button className="my-4" color="primary" type="submit">
          {t("actions.send")}
        </Button>
      </div>
    </Form>
  );
}

function VerificationForm(props) {
  const { t } = useTranslation();
  const { onResetPassword, data, onChange } = props;

  const [emailFocus, setEmailFocus] = useState("");
  const [tokenFocus, setTokenFocus] = useState("");

  const [passwordFocus, setPasswordFocus] = useState("");

  const [repeatPasswordFocus, setRepeatPasswordFocus] = useState("");

  return (
    <Form role="form" onSubmit={onResetPassword}>
      <Alert color="success">{t("auth.reset.sent")}</Alert>
      <div className="text-center text-muted mb-4">
        <small>{t("auth.reset.typeToken")}</small>
      </div>
      <FormGroup className={"mb-3 " + emailFocus}>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("users.fields.email")}
            type="email"
            onFocus={() => setEmailFocus("focused")}
            onBlur={() => setEmailFocus("")}
            name="email"
            value={data?.email}
            onChange={onChange}
          ></Input>
        </InputGroup>
      </FormGroup>
      <FormGroup className={"mb-3 " + tokenFocus}>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("auth.reset.token")}
            type="text"
            onFocus={() => setTokenFocus("focused")}
            onBlur={() => setTokenFocus("")}
            name="token"
            value={data?.token}
            onChange={onChange}
          ></Input>
        </InputGroup>
      </FormGroup>
      <FormGroup className={passwordFocus}>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("auth.newPassword")}
            type="password"
            onFocus={() => setPasswordFocus("focused")}
            onBlur={() => setPasswordFocus("")}
            name="password"
            value={data?.password}
            onChange={onChange}
          ></Input>
        </InputGroup>
        <PasswordStrengthBar
          password={data?.password}
          scoreWords={[
            t("users.password.weak"),
            t("users.password.weak"),
            t("users.password.enough"),
            t("users.password.strong"),
            t("users.password.veryStrong"),
          ]}
          shortScoreWord={t("users.password.tooShort")}
          minLength={8}
          onChangeScore={(score, feedback) => {
            onChange({ target: { name: 'passwordScore', value: score } });
          }}
        />
      </FormGroup>
      <FormGroup
        className={`${repeatPasswordFocus} ${data?.repeatPassword
          ? data?.repeatPasswordValid
            ? "has-success"
            : "has-danger"
          : ""
          }`}
      >
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("users.fields.repeatPassword")}
            className={`${data?.repeatPassword
              ? data?.repeatPasswordValid
                ? "is-valid"
                : "is-invalid"
              : ""
              }`}
            type="password"
            onFocus={() => setRepeatPasswordFocus("focused")}
            onBlur={() => setRepeatPasswordFocus("")}
            name="repeatPassword"
            value={data?.repeatPassword}
            onChange={onChange}
          ></Input>
        </InputGroup>
      </FormGroup>
      <div className="text-center">
        <Button className="my-4" color="primary" type="submit" disabled={!data?.token || data?.passwordScore < 2 || data.password !== data.repeatPassword}>
          {t("auth.reset.do")}
        </Button>
      </div>
    </Form>
  );
}

function Confirmation() {
  const { t } = useTranslation();
  return (
    <Form role="form">
      <div className="text-center text-muted mb-4">
        <small>{t("auth.reset.changed")}</small>
      </div>
      <div className="text-center">
        <LocalizedLink to="/">
          <Button className="my-4" color="dark" type="button">
            {t("home")}
          </Button>
        </LocalizedLink>
        &nbsp;
        <LocalizedLink to="/login">
          <Button className="my-4" color="primary" type="button">
            {t("auth.login.login")}
          </Button>
        </LocalizedLink>
      </div>
    </Form>
  );
}

function ResetPasswordCard() {
  const { t } = useTranslation();
  const { email, token } = useParams();

  const [step, setStep] = useState(1);

  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const onRequestToken = (e) => {
    setError(false);
    setLoading(true);
    apiDriver
      .post(`${config.api.iam}Authentication/PasswordResetToken`, data)
      .subscribe({
        next: () => setStep(2),
        error: () => setError(true),
        complete: () => setLoading(false),
      });
    e.preventDefault(e);
  };

  const onResetPassword = (e) => {
    setError(false);

    if (!data?.token || data?.passwordScore < 2 || data.password !== data.repeatPassword) {
      e.preventDefault(e);
      setLoading(false);
      return;
    }

    setLoading(true);
    apiDriver
      .post(`${config.api.iam}Authentication/PasswordReset`, data)
      .subscribe({
        next: () => setStep(3),
        error: () => setError(true),
        complete: () => setLoading(false),
      });
    e.preventDefault(e);
  };

  useEffect(() => {
    setData({
      email: decodeURIComponent(email || ""),
      token: decodeURIComponent(token || ""),
    });
    if (token) {
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (data.password !== "") {
      data.repeatPasswordValid = data.password === data.repeatPassword;
    }
  }, [data?.password, data?.repeatPassword]);

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardHeader>
          <CardTitle className="text-center" tag="h4">
            {t("auth.reset.reset")}
          </CardTitle>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {error && <Alert color="danger">{t("errors.common.title")}</Alert>}
          {loading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {step === 1 ? (
            <ResetForm
              onRequestToken={onRequestToken}
              data={data}
              onChange={onChange}
            />
          ) : (
            <React.Fragment />
          )}
          {step === 2 ? (
            <VerificationForm
              onResetPassword={onResetPassword}
              data={data}
              onChange={onChange}
            />
          ) : (
            <React.Fragment />
          )}
          {step === 3 ? <Confirmation /> : <React.Fragment />}
        </CardBody>
      </Card>
    </>
  );
}

function ResetPassword() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("auth.reset.reseting")} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5 mt-5 pt-5" md="8" lg="8" xl="6">
                <ResetPasswordCard />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
