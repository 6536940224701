import React, { } from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import PageEmbed from "components/CMS/PageEmbed";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";

function ContactView() {
  const { t, i18n } = useTranslation();

  return (
    <div className="wrapper">
      <div className="main">
        <Container fluid>
          <Row className="mb-4 pt-5">
            <Col className="ml-auto mr-auto text-center mt-5" md="8">
              <h1 className="title">{t("contact.contact")}</h1>
              <h4 className="desc">{t("contact.header")}</h4>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" lg={4}>
              <PageEmbed id="48f7e478-6f53-4caa-a35a-f6c792bc914b" content />
            </Col>
            <Col className="mr-auto" lg={6} xl={4}>
              <Card
                className="bg-secondary p-3"
                id="contact-form"
                method="post"
              >
                <CardBody>
                  <ContactForm subject={t("contact.form")} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("contact.contact")} - Printweb.pl</title>
      </Helmet>
      <ContactView />
    </>
  );
}

export default Contact;
