import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Form,
  Alert,
} from "reactstrap";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";
import LocalizedLink from "components/LocalizedLink";

function ContactFormContent(props) {
  const { subject } = props;
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [givenNameFocus, setGivenNameFocus] = React.useState("");
  const [surnameFocus, setSurnameFocus] = React.useState("");
  const [emailFocus, setEmailFocus] = React.useState("");
  const [phoneFocus, setPhoneFocus] = React.useState("");
  const [businessFocus, setBusinessFocus] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [token, setToken] = useState();

  const user = useUser();

  const [contactData, setContactData] = React.useState(
    user
      ? {
        givenName: user?.givenName ?? "",
        surname: user?.surname ?? "",
        email: user?.email ?? "",
        phone: user?.phone ?? "",
        business: "",
        content: "",
      }
      : {
        givenName: "",
        surname: "",
        email: "",
        phone: "",
        business: "",
        content: "",
      },
  );
  const onChangeContactData = (event) => {
    const { name, value } = event.target;
    setContactData({ ...contactData, [name]: value });
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const tok = await executeRecaptcha("CRMClientTicketPut");
    setToken(tok);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const onClickSubmit = () => {
    handleReCaptchaVerify();

    apiDriver
      .put(`${config.api.crm}${i18n.resolvedLanguage}/Tickets`, {
        culture: i18n.resolvedLanguage,
        subject: subject,
        description: `${contactData.content}\n\n- Nazwa firmy: ${contactData.business}`,
        guest: {
          givenName: contactData.givenName,
          surname: contactData.surname,
          email: contactData.email,
          phone: contactData.phone,
        },
        recaptchaResponse: token,
      })
      .pipe(
        map((r) => r.response),
        catchError(() => {
          setStatus(false);
          return of(undefined);
        }),
      )
      .subscribe((r) => {
        if (!r) {
          return;
        }
        setStatus(true);
      });
  };

  return (
    <>

      {status === true ? (
        <Alert color="success">
          <p>
            <b>{t("contact.feedback.success.title")}</b>
          </p>
          <p>{t("contact.feedback.success.content")}</p>
          <div className="text-right">
            <LocalizedLink to="/">
              <Button color="dark">{t("home")}</Button>
            </LocalizedLink>
          </div>
        </Alert>
      ) : (
        <React.Fragment />
      )}
      {status === false ? (
        <Alert color="danger">{t("errors.common.header")}</Alert>
      ) : (
        <React.Fragment />
      )}

      <Form className={status !== true ? "p-3" : "d-none"}>
        <Row>
          <Col md="6">
            <FormGroup className={givenNameFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  aria-label={t("users.fields.givenName")}
                  placeholder={t("users.fields.givenName")}
                  type="text"
                  name="givenName"
                  value={contactData.givenName}
                  onChange={onChangeContactData}
                  onFocus={() => setGivenNameFocus("focused")}
                  onBlur={() => setGivenNameFocus("")}
                ></Input>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className={surnameFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-tag"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  aria-label={t("users.fields.surname")}
                  placeholder={t("users.fields.surname")}
                  type="text"
                  name="surname"
                  value={contactData.surname}
                  onChange={onChangeContactData}
                  onFocus={() => setSurnameFocus("focused")}
                  onBlur={() => setSurnameFocus("")}
                ></Input>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className={businessFocus}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-building"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={t("customers.fields.businessName")}
              type="text"
              name="business"
              value={contactData.business}
              onChange={onChangeContactData}
              onFocus={() => setBusinessFocus("focused")}
              onBlur={() => setBusinessFocus("")}
            ></Input>
          </InputGroup>
        </FormGroup>
        <FormGroup className={emailFocus}>
          <label>{t("contact.contact")}</label>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={t("users.fields.email")}
              type="email"
              name="email"
              value={contactData.email}
              onChange={onChangeContactData}
              onFocus={() => setEmailFocus("focused")}
              onBlur={() => setEmailFocus("")}
            ></Input>
          </InputGroup>
        </FormGroup>
        <FormGroup className={phoneFocus}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-phone"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={t("users.fields.phone")}
              type="text"
              name="phone"
              value={contactData.phone}
              onChange={onChangeContactData}
              onFocus={() => setPhoneFocus("focused")}
              onBlur={() => setPhoneFocus("")}
            ></Input>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label>{t("contact.fields.content")}</label>
          <Input
            className="form-control-alternative"
            id="content"
            name="content"
            value={contactData.content}
            onChange={onChangeContactData}
            rows="6"
            type="textarea"
          ></Input>
        </FormGroup>
        <Row className="justify-content-end">
          <Col className="text-left" md="6">
          </Col>
          <Col className="text-right" md="6">
            <Button
              color="primary"
              type="button"
              onClick={() => onClickSubmit()}
            >
              {t("actions.sendMessage")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default function ContactForm(props) {
  const { subject } = props;

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Lfz9eckAAAAAMwNmbf9XKEl3upXvUehQHdsNO1Y"
        useEnterprise={true}
      >
        <ContactFormContent {...props} />
      </GoogleReCaptchaProvider>
    </>
  )
}