import React, { useState, useEffect } from "react";

import { Row, Col, Button, Spinner } from "reactstrap";

import CustomizationBadges from "components/Products/Product/CustomizationBadges";
import StatusBadge from "components/Orders/StatusBadge";
import ShippingLabel from "components/Orders/ShippingLabel";
import NotesModal from "./NotesModal";
import ProofModal from "./ProofModal";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import Uploader from "views/Cart/Uploader";
import { useTranslation } from "react-i18next";
import RealizationTerm from "components/Cart/RealizationTerm";
import ProductPersonalization from "components/product/ProductPersonalization";
import { CartProductProject } from "views/Cart/CartProduct";


const OrderProductShippings = (props) => {
  const { orderProduct, order } = props;
  const orderProductShippings = [
    ...order.shippings.filter((s) =>
      s.products.some((p) => p.productId === orderProduct.id),
    ),
  ];
  return (
    <>
      {orderProductShippings.map((shipping) => (
        <ShippingLabel key={shipping.id} shipping={shipping} />
      ))}
    </>
  )
}

const OrderProductProofModalLastPreview = (props) => {
  const { orderProduct, order, lastPreview, reload } = props;
  const { t } = useTranslation();

  return (
    <>
      <ProofModal
        entityType="Preview"
        orderProduct={orderProduct}
        order={order}
        file={lastPreview}
        title={t("order.product.proof")}
        buttonTitle={t("order.actions.goToAcceptance")}
        buttonProps={{ color: "primary", block: true }}
        callback={reload}
      />
    </>
  );
}

const OrderProductProofModalLastProject = (props) => {
  const { orderProduct, order, lastProject, reload } = props;
  const { t } = useTranslation();

  return (
    <>
      <ProofModal
        entityType="Project"
        orderProduct={orderProduct}
        order={order}
        file={lastProject}
        title={t("products.fields.project")}
        buttonTitle={t("order.actions.goToAcceptance")}
        buttonProps={{ color: "primary", block: true }}
        callback={reload}
      />
    </>
  );
}
const OrderProductPreviewLastPreview = (props) => {
  const { orderProduct, order, lastPreview } = props;
  const [previews, setPreviews] = useState(undefined);
  const { t, i18n } = useTranslation();

  const loadPreviews = () => {
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductPreviews/${lastPreview.objectName}/Preview?Take=1`,)
      .subscribe({
        next: (response) => setPreviews(response.response),
        error: console.log
      });
  };

  useEffect(() => {
    if (previews === undefined) loadPreviews();
  }, [previews]);

  return (
    <>
      {previews?.length > 0 && <img
        alt={t("products.fields.project")}
        src={previews[0]}
        className="d-block mx-auto mb-3"
        style={{ maxHeight: "128px", maxWidth: "100%" }}
      />}
    </>
  );
}

const OrderProductPreviewLastProject = (props) => {
  const [previews, setPreviews] = useState(undefined);
  const { orderProduct, order, lastProject } = props;
  const { t, i18n } = useTranslation();

  const loadPreviews = () => {
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductProjects/${lastProject.objectName}/Preview?Take=1`,)
      .subscribe({
        next: (response) => setPreviews(response.response),
        error: console.log
      });
  };

  useEffect(() => {
    if (previews === undefined) loadPreviews();
  }, [previews]);

  return (
    <>
      {previews?.length > 0 ? <img
        alt={t("products.fields.project")}
        src={previews[0]}
        className="d-block mx-auto mb-3"
        style={{ maxHeight: "128px", maxWidth: "100%" }}
      /> : <div
        style={{
          height: '96px',
          width: '100%',
          backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${orderProduct?.product?.id}/Photo)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>}
    </>
  )
}

const OrderProductUploader = (props) => {
  const { orderProduct, reload, reloadProjects, projects, onDeleteProject } = props;
  const { t, i18n } = useTranslation();

  const onUploadComplete = () => setTimeout(reloadProjects, 1000);
  const onClick = () => apiDriver
    .get(`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/Unlock`)
    .subscribe({
      next: () => {
        setTimeout(reload, 3000);
      },
      error: (err) => alert('Can`t update order product')
    });

  const uploadedProjects = projects?.filter(p => p.accepted !== false && p.accepted !== true) || [];


  return (
    <>
      <div>
        <b>{t("order.actions.uploadProject")}</b>
      </div>
      <Uploader
        id={`project${orderProduct.id}`}
        url={`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductProjects/FILENAME`}
        isPresigned={true}
        onUploadComplete={onUploadComplete}
        large={true}
      />
      <br />
      <div className="text-left">
        {uploadedProjects.map(p => <CartProductProject key={p.objectName} product={orderProduct} project={p} onDelete={onDeleteProject} />)}
      </div>
      <div className="text-right">
        <Button color="primary" onClick={onClick}>{t('actions.send')}</Button>
      </div>
    </>
  );
}

const OrderProductButtons = (props) => {
  const { orderProduct, order, onCreateNote, lastPreview, lastProject, reload } = props;
  const { t } = useTranslation();
  return (
    <>
      <Button color="default" outline size="sm" className="d-none">
        <i className="fas fa-shopping-cart"></i>{" "}
        {t("order.actions.orderAgain")}
      </Button>
      {orderProduct.status && orderProduct.status.tag >= 6 &&
        <>
          {lastPreview && <ProofModal
            file={lastPreview}
            orderProduct={orderProduct}
            order={order}
            entityType="Preview"
            buttonTitle={t("order.actions.viewProof")}
            buttonProps={{ size: "sm", color: "default", outline: true }}
            callback={reload}
          />}
          {lastProject && <ProofModal
            file={lastProject}
            orderProduct={orderProduct}
            order={order}
            entityType="Project"
            buttonTitle={t("order.actions.viewProject")}
            buttonProps={{ size: "sm", color: "default", outline: true }}
            callback={reload}
          />}
        </>}
    </>
  )
}

const OrderProductImage = (props) => {
  const { orderProduct, order, lastProject, lastPreview } = props;
  const { i18n } = useTranslation();

  if (lastPreview) {
    return <OrderProductPreviewLastPreview order={order} orderProduct={orderProduct} lastPreview={lastPreview} />
  }

  if (lastProject) {
    return <OrderProductPreviewLastProject order={order} orderProduct={orderProduct} lastProject={lastProject} />
  }

  return (
    <>
      <div
        style={{
          height: '96px',
          width: '100%',
          backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${orderProduct?.product?.id}/Photo)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>
    </>
  )
}

function OrderProduct(props) {
  const { t, i18n } = useTranslation();
  const { orderProduct, order, reload, onCreateNote } = props;
  const product = orderProduct.product;
  const productTranslation = product ? product.translations[0] : null;
  const personalization = orderProduct.personalization;

  const [projects, setProjects] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [lastProject, setLastProject] = useState(null);
  const [lastPreview, setLastPreview] = useState(null);


  const filesSortByDate = (b, a) =>
    a.stat.lastModified.localeCompare(b.stat.lastModified);
  const getLatest = (arr) => [...arr].sort(filesSortByDate)[0];

  const loadProductPreviews = () => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductPreviews/`,
      )
      .pipe(
        map((response) => {
          setPreviews(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const loadProductProjects = () => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductProjects/`,
      )
      .pipe(
        map((response) => {
          setProjects(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  useEffect(() => {
    loadProductPreviews();
    loadProductProjects();
  }, []);

  useEffect(() => {
    if (projects && projects.length > 0 && !lastProject) {
      const lastFile = getLatest(projects);
      setLastProject(lastFile);
    }
  }, [projects]);

  useEffect(() => {
    if (previews && previews.length > 0 && !lastPreview) {
      const lastFile = getLatest(previews);
      setLastPreview(lastFile);
    }
  }, [previews]);

  const onDeleteProject = (id, name) => {
    setProjects([...projects.filter(p => p.objectName !== name)]);
  }

  return (
    <Row>
      <Col md="2" className="pb-3 text-center" onClick={reload}>
        <OrderProductImage orderProduct={orderProduct} order={order} lastPreview={lastPreview} lastProject={lastProject} />
      </Col>
      <Col md="6" className="pb-3">
        <h5>
          {orderProduct.number} &nbsp;{" "}
          {productTranslation?.title || orderProduct?.title || t('common.untitled')}{" "}
          &nbsp;{" "}
          {personalization?.amount ? (
            <span>({personalization.amount} {t('price.piece')})</span>
          ) : (
            <Spinner />
          )}{" "}
          &nbsp; <StatusBadge status={orderProduct.status} />
        </h5>
        <div className="mb-3">
          <ProductPersonalization product={orderProduct} full={true} />
        </div>
        <div className="mt-auto">
          <OrderProductButtons
            orderProduct={orderProduct}
            order={order}
            reload={reload}
          />
        </div>
      </Col>
      <Col md="4" className="pb-3 text-center">
        {orderProduct?.status?.tag === 2 && !orderProduct?.personalization?.project && <OrderProductUploader orderProduct={orderProduct} order={order} reload={reload} reloadProjects={loadProductProjects} projects={projects} onDeleteProject={onDeleteProject} />}
        {orderProduct?.status?.tag === 4 && orderProduct?.personalization?.project && lastProject && <OrderProductProofModalLastProject orderProduct={orderProduct} order={order} lastProject={lastProject} reload={reload} />}
        {orderProduct?.status?.tag === 5 && !orderProduct?.personalization?.project && lastPreview && <OrderProductProofModalLastPreview orderProduct={orderProduct} order={order} lastPreview={lastPreview} reload={reload} />}
        {orderProduct?.status?.tag === 7 && <OrderProductShippings orderProduct={orderProduct} />}
      </Col>
      <Col md="8" className="text-primary">
      </Col>
      <Col md="2" className="text-right text-primary">
        <small className="text-muted">
          {t("products.fields.realizationTerm")}:
        </small>
        <h5 className="text-primary m-0 p-0">
          <RealizationTerm term={orderProduct.realizationTerm} />
        </h5>
      </Col>
      <Col md="2" className="text-right text-primary">
        <small className="text-muted">
          {t('price.price.net')} {new Intl.NumberFormat(i18n.resolvedLanguage, {
            style: "currency",
            currency: "PLN",
          }).format(orderProduct.priceObj.netCost)}
        </small>
        <h5 className="text-primary m-0 p-0">
          {t('price.price.gross')} {new Intl.NumberFormat(i18n.resolvedLanguage, {
            style: "currency",
            currency: "PLN",
          }).format(orderProduct.priceObj.grossCost)}
        </h5>
      </Col>
      <Col md="12">
        <hr className="w-100 text-primary p-0 my-3" />
      </Col>
    </Row>
  );
}

export default OrderProduct;
