import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import Address from "./Address";
import BillingInfo from "./BillingInfo";
import CartProductSummary from "./CartProductSummary";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import apiDriver from "stores/api.driver";
import config from "config/global";
import { url } from "stores/cart/epics";
import { useTranslation } from "react-i18next";
import RealizationTerm from "components/Cart/RealizationTerm";
import OrderValue from "components/Orders/OrderValue";

function CartSummary(props) {
  const { t, i18n } = useTranslation();
  const {
    cart,
    products,
    shippingMethod,
    paymentMethod,
    setCartEmail,
    setCartPhone,
  } = props;

  const [fullShippingMethod, setFullShippingMethod] = useState(null);
  const [fullPaymentMethod, setFullPaymentMethod] = useState(null);
  const [value, setValue] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isInvalidCoupon, setInvalidCoupon] = useState(false);

  const applyCoupon = () => {
    apiDriver
      .post(`${url()}/ApplyCoupon`, { coupon: coupon })
      .subscribe({
        next: (response) => setValue(response.response),
        error: () => setInvalidCoupon(true)
      })
  };

  const fetchValue = () => {
    apiDriver.get(`${url()}/Value`).subscribe({
      next: (response) => setValue(response.response),
    });
  };

  const loadPaymentMethod = (id) => {
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/PaymentMethods/${id}`)
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        setFullPaymentMethod(data);
      });
  };

  const loadShippingMethod = (id) => {
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/ShippingMethods/${id}`)
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        setFullShippingMethod(data);
      });
  };

  useEffect(() => {
    if (!value && cart?.id) fetchValue();
  }, [value]);

  useEffect(() => {
    loadPaymentMethod(paymentMethod.id);
  }, [paymentMethod]);

  useEffect(() => {
    loadShippingMethod(shippingMethod.id);
  }, [shippingMethod]);

  return (
    <Row>
      <Col xl={8} className="py-lg-3">
        <h2 className="text-uppercase">{t("cart.summary")}</h2>
        {products.map((product) => (
          <CartProductSummary key={product.id} product={product} />
        ))}
      </Col>
      <Col xl={4} className="p-lg-3" style={{ backgroundColor: '#f4f5f7' }}>
        {fullShippingMethod && <h5>{fullShippingMethod.translations[0]?.title}</h5>}
        {cart.shippingAddress && <Address address={cart.shippingAddress} />}
        {cart.senderAddress && <><hr /><h5>{t('customers.senderAddress.senderAddress')}</h5><Address address={cart.senderAddress} /></>}
        {cart.collectPoint && <Address address={cart.collectPoint} />}
        <hr />
        {fullPaymentMethod && <h5>{fullPaymentMethod.translations[0]?.title}</h5>}
        {cart.billingInfo && <BillingInfo info={cart.billingInfo} input={false} />}
        <hr />
        {value ? (
          <>
            <OrderValue value={value} products={products} />
            <Row>
              <Col md="12">
                <small className="text-muted text-uppercase">
                  {t("order.coupon")}
                </small>
              </Col>
              <Col>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder={t("coupons.fields.code")}
                    onChange={(e) => setCoupon(e.target.value)}
                    invalid={isInvalidCoupon}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color={isInvalidCoupon ? "danger" : "primary"} outline onClick={applyCoupon}>
                      {t("actions.apply")}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            {!cart.userId && (
              <>
                <Row>
                  <Col md="12">
                    <small className="text-muted text-uppercase">
                      {t("users.fields.email")}
                    </small>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Input
                        name="email"
                        type="text"
                        placeholder={t("users.fields.email")}
                        onChange={(e) => setCartEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <small className="text-muted text-uppercase">
                      {t("users.fields.phone")}
                    </small>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Input
                        name="phone"
                        type="text"
                        placeholder={t("users.fields.phone")}
                        onChange={(e) => setCartPhone(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <React.Fragment />
        )}
      </Col>
      <Col md={12}>
        <hr className="my-3 p-0 w-100" />
      </Col>
    </Row>
  );
}
export default CartSummary;
