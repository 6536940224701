import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import apiDriver from "stores/api.driver";
import { Spinner, Alert } from 'reactstrap';
import config from "config/global";

export default function PaymentGate() {
  const { id, method } = useParams();
  const { i18n } = useTranslation();
  
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ payment, setPayment ] = useState();  
  
  const loadPayment = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/Payments/${id}`;
    apiDriver.get(url)
      .subscribe({
        next: (response) => setPayment(response.response),
        error: () => setError(true),
        complete: () =>  setLoading(false)
      })
  }

  const doDotPayPayment = () => {
    const url = `${config.api.dotpay}Payments/${id}`;
    apiDriver.get(url, {}, 'text/plain')
      .subscribe({
        next: (response) => window.location.href = response.response,
        error: () => setError(true),
        complete: () =>  setLoading(false)
      })
  }

  useEffect(() => {
    if(!payment) {
      return;
    }

    switch(payment.method?.integration) {
      case 'DotPay':
        doDotPayPayment();
        break;
      default:
        break;
    }
  }, [ payment ]);

  useEffect(() => {
    if(method) {
      return;
    }
    if(!i18n.resolvedLanguage || !id) {
      return;
    }
    loadPayment();
  }, [ i18n.resolvedLanguage ]);

  useEffect(() => {
    if(!method) {
      return;
    }
    switch(method) {
      case 'dotpay':
        doDotPayPayment();
        break;
      default:
        break;
    }
  }, [ method ]);

  return (
    <>
      <div className="text-center m-5 p-5"><Spinner /></div>
      {error && <Alert color="danger">Not found</Alert>}
    </>
  );
}
