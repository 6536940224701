import React, { useState } from "react";
import { Row, Col, Card, CardBody, Spinner, Button, Form, FormGroup, InputGroup, Input, InputGroupAddon } from "reactstrap";
import BillingInfoForm from "./BillingInfoForm";

import { map, catchError } from "rxjs/operators";

import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";
import Country from "components/AddressForm/Country";

function BillingInfo(props) {
  const { info, currentInfo, setInfo } = props;
  const active = info.id === currentInfo;
  const onClick = () => setInfo(info);

  return (
    <Card
      data-billing-info={info.id}
      className={
        active ? "border border-primary text-primary my-2" : "border my-2"
      }
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody className="px-3 py-2">
        <Row>
          <Col md={6}>
            <label className="m-0 p-0">{info.buyerName}</label>
            <br />
            <label className="m-0 p-0">{info.vatID}</label>
            <br />
            <label className="m-0 p-0">{info.email}</label>
          </Col>
          <Col md={6}>
            <label className="m-0 p-0">{info.line1}</label>
            <br />
            <label className="m-0 p-0">{info.line2}</label>
            <label className="m-0 p-0">
              {info.postalCode} {info.city}
            </label>
            <br />
            <label className="m-0 p-0"><Country countryCode={info.countryCode} /></label>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

function BillingInfoSelector(props) {
  const { t, i18n } = useTranslation();
  const { infos, currentInfo, setInfo, canCreate, onAddInfo, onSearch } = props;
  const [ searchTerm, setSearchTerm ] = useState("");

  const dbInfos = infos
    ? [...infos.filter((address) => address.id !== "new")]
    : [];
  const defaultInfo = dbInfos.find((address) => address.default);
  const standardInfos = dbInfos.filter((address) => !address.default);

  const [draftInfo, setDraftInfo] = useState({ countryCode: "pl" });
  const [draftInfoError, setDraftInfoError] = useState("");
  const [draftInfoLoading, setDraftInfoLoading] = useState(false);

  const onChangeDraftInfo = (e) =>
    setDraftInfo({ ...draftInfo, [e.target.name]: e.target.value });
  const addDraftAddress = () => {
    if (!draftInfo.vatID) {
      return setDraftInfoError(t("customers.billingInfo.errors.vatID"));
    }
    if (!draftInfo.line1) {
      return setDraftInfoError(t("customers.shippingAddress.errors.line1"));
    }
    if (!draftInfo.postalCode) {
      return setDraftInfoError(
        t("customers.shippingAddress.errors.postalCode"),
      );
    }
    if (!draftInfo.city) {
      return setDraftInfoError(t("customers.shippingAddress.errors.city"));
    }
    if (!draftInfo.countryCode) {
      return setDraftInfoError(
        t("customers.shippingAddress.errors.countryCode"),
      );
    }
    if (!draftInfo.email) {
      return setDraftInfoError(t("customers.shippingAddress.errors.email"));
    }
    setDraftInfoError("");
    setDraftInfoLoading(true);
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/`,
        draftInfo,
      )
      .pipe(
        map((response) => response.response),
        catchError(() => {
          return setDraftInfoError(t("errors.common.title"));
        }),
      )
      .subscribe((data) => {
        setDraftInfoLoading(false);
        onAddInfo(data);
      });
  };


  return (
    <>
      <FormGroup>
        <InputGroup>
          <Input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder={t('common.search')} />
          <Button color="primary" outline onClick={e => onSearch(searchTerm)}><i className="fas fa-search"></i></Button>
      </InputGroup>
      </FormGroup>
      <hr className="my-3 p-0" />
      {defaultInfo && (
        <>
          <BillingInfo
            key={defaultInfo.id}
            info={defaultInfo}
            currentInfo={currentInfo}
            setInfo={setInfo}
          />
          <hr className="my-3 p-0" />
        </>
      )}
      {standardInfos.length > 0 ? (
        <>
          {standardInfos
            .filter((info) => info.id !== "new" && !info.default)
            .map((info) => (
              <BillingInfo
                key={info.id}
                info={info}
                currentInfo={currentInfo}
                setInfo={setInfo}
              />
            ))}
        </>
      ) : (
        <React.Fragment />
      )}
      {canCreate ? (
        <>
          <hr className="my-3 p-0 w-100" />
          <h6 className="text-uppercase">{t("customers.billingInfo.add")}</h6>
          <Form onSubmit={addDraftAddress}>
            <BillingInfoForm
              billingInfo={draftInfo || {}}
              onChange={onChangeDraftInfo}
              readOnly={draftInfoLoading}
              setBillingInfo={setDraftInfo}
            />
            <Row>
              <Col sm={12}>
                {draftInfoError ? (
                  <div>
                    <p className="alert alert-danger py-2">{draftInfoError}</p>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </Col>
              <Col sm={12}>
                {draftInfoLoading ? (
                  <Spinner className="float-right" />
                ) : (
                  <Button onClick={addDraftAddress} block>
                    {t("customers.billingInfo.add")}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <React.Fragment />
      )}
    </>
  );
}

export default BillingInfoSelector;
