import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Spinner,
  Tooltip,
} from "reactstrap";

import ReactHtmlParser from "react-html-parser";

import config from "config/global";

import { useSelector, useDispatch } from "react-redux";

import Summary from "./Summary";
import Attribute from "./Attribute";
import AdditiveGroup from "./AdditiveGroup";
import Additive from "./Additive";
import RebateInfo from "./RebateInfo";
import Project from "./Project";
import Format from "./Format";
import Pages from "./Pages";
import Schema from "./Schema";
import Template from "./Template";
import PricingWrapper from "./PricingWrapper";
import Helmet from "react-helmet";

import apiDriver from "stores/api.driver";
import * as cartActions from "stores/cart/actions";
import RealizationTimes from "./RealizationTimeType";
import { useTranslation } from "react-i18next";
import Checking from "components/product/Checking";
import useUser from "hooks/useUser";

function Product() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const user = useUser();
  const [pricingTimer, setPricingTimer] = useState(null);
  const [amount, setAmount] = useState(0);
  const [color, setColor] = useState([]);
  const [size, setSize] = useState({ x: 0, y: 0 });
  const [pages, setPages] = useState({ inside: 1, outside: 0 });
  const [options, setOptions] = useState([]);
  const [additives, setAdditives] = useState([]);
  const [project, setProject] = useState(false);
  const [product, setProduct] = useState({});
  const [pricings, setPricings] = useState([]);
  const [pricingLoaded, setPricingLoaded] = useState(false);
  const [updatePricings, setUpdatePricings] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const cart = useSelector((state) => state.cart);

  const [realizationTimeType, setRealizationTimeType] = useState("Standard");
  const realizationTimes =
    product?.productConfiguration?.realizationTimes || [];

  const [isTooltipFormatOpen, setTooltipFormatOpen] = useState();
  const toggleTooltipFormat = () => setTooltipFormatOpen(!isTooltipFormatOpen);
  const [isTooltipProjectOpen, setTooltipProjectOpen] = useState();
  const toggleTooltipProject = () =>
    setTooltipProjectOpen(!isTooltipProjectOpen);
  const [isTooltipPagingOpen, setTooltipPagingOpen] = useState();
  const toggleTooltipPaging = () => setTooltipPagingOpen(!isTooltipPagingOpen);

  const unitModifier =
    product &&
      product.productConfiguration &&
      product.productConfiguration.format &&
      product.productConfiguration.format.unit
      ? product.productConfiguration.format.unit
      : 1;
  const dispatch = useDispatch();

  let conflictedAdditives = [];
  let conflictedOptions = [];

  if (
    product &&
    product.additives &&
    product.additives.length > 0 &&
    additives &&
    additives.length > 0
  ) {
    let cA = product.additives
      .filter((additive) => additives.includes(additive.id))
      .map((additive) => {
        let aConflicts = [];
        aConflicts = aConflicts.concat(
          [...additive.additiveConflicts]
            .filter((conflict) => conflict.firstId === additive.id)
            .map((conflict) => conflict.secondId),
        );
        aConflicts = aConflicts.concat(
          [...additive.additiveConflicts]
            .filter((conflict) => conflict.secondId === additive.id)
            .map((conflict) => conflict.firstId),
        );
        let oConflicts = [...additive.optionConflicts].map(
          (conflict) => conflict.optionId,
        );
        return { id: additive.id, additives: aConflicts, options: oConflicts };
      });
    cA.forEach((conflicts) => {
      conflictedAdditives = conflictedAdditives.concat(conflicts.additives);
      conflictedOptions = conflictedOptions.concat(conflicts.options);
    });
  }

  if (
    product &&
    product.options &&
    product.options.length > 0 &&
    options &&
    options.length > 0
  ) {
    let cO = product.options
      .filter((option) => options.includes(option.id))
      .map((option) => {
        let oConflicts = [];
        oConflicts = oConflicts.concat(
          [...option.optionConflicts]
            .filter((conflict) => conflict.firstId === option.id)
            .map((conflict) => conflict.secondId),
        );
        oConflicts = oConflicts.concat(
          [...option.optionConflicts]
            .filter((conflict) => conflict.secondId === option.id)
            .map((conflict) => conflict.firstId),
        );
        let aConflicts = [...option.additiveConflicts].map(
          (conflict) => conflict.additiveId,
        );
        return { id: option.id, additives: aConflicts, options: oConflicts };
      });
    cO.forEach((conflicts) => {
      conflictedAdditives = conflictedAdditives.concat(conflicts.additives);
      conflictedOptions = conflictedOptions.concat(conflicts.options);
    });
  }

  if (
    size.x === 0 &&
    size.y === 0 &&
    product &&
    product.printFormats &&
    product.printFormats.length > 0
  ) {
    setSize(product.printFormats[0]);
  }

  const validatePages = () => {
    const min = product?.productConfiguration?.pages?.minimal || {
      inside: 0,
      outside: 0,
    };
    const max = product?.productConfiguration?.pages?.maximal || {
      inside: 1000000,
      outside: 1000000,
    };
    var names = ["inside", "outside"];
    names.forEach((name) => {
      if (pages[name] < min[name]) {
        setPages({ ...pages, [name]: min[name] });
        return true;
      } else if (pages[name] > max[name]) {
        setPages({ ...pages, [name]: max[name] });
        return true;
      }
    });
  };

  const validateSize = () => {
    const min = product?.productConfiguration?.format?.minimal || {
      x: 0,
      y: 0,
    };
    const max = product?.productConfiguration?.format?.maximal || {
      x: 1000000,
      y: 1000000,
    };
    var names = ["x", "y"];
    names.forEach((name) => {
      if (size[name] < min[name]) {
        setSize({ ...size, [name]: min[name] });
        return true;
      } else if (size[name] > max[name]) {
        setSize({ ...size, [name]: max[name] });
        return true;
      }
    });
  };

  const validateProduct = () => {
    if (validateSize()) {
      return true;
    }
    if (validatePages()) {
      return true;
    }
  };

  const addCustomPricing = (amount) => {
    if (pricings.some((p) => p.amount === amount)) {
      return;
    }
    setPricings([
      ...pricings,
      {
        custom: true,
        amount: amount,
        costNet: 0.0,
        costGross: 0.0,
        weight: 0.0,
      },
    ]);
    setUpdatePricings(true);
  };

  const removeCustomPricing = (amount) => {
    let newPricings = [...pricings].filter(
      (p) => !p.custom || (p.custom && p.amount !== amount),
    );
    setPricings(newPricings);
    setUpdatePricings(true);
  };

  const getCalculationDTO = (customAmount) => {
    customAmount =
      customAmount ||
      (pricings && pricings.length
        ? [...pricings.filter((p) => p.custom).map((p) => p.amount)]
        : []);
    return {
      id: product.id,
      size: { x: size.x * unitModifier, y: size.y * unitModifier },
      pages: {
        inside:
          pages.inside ||
          product?.productConfiguration?.pages?.default?.inside ||
          1,
        outside:
          pages.outside ||
          product?.productConfiguration?.pages?.default?.outside ||
          0,
      },
      options: options,
      additives: additives,
      project: project,
      checking: Checking.None,
      customAmount: customAmount,
      colorProfiles: getColorsDTO(),
      realizationTimeType: realizationTimeType,
    };
  };

  const fetchPricing = () => {
    if (!product) {
      return;
    }

    if (validateProduct()) {
      return;
    }

    let customAmounts = null;
    if (pricings?.length === 0) {
      let tmp = localStorage.getItem(product.id + ".customAmounts");
      if (tmp) {
        tmp = JSON.parse(tmp);
        customAmounts = tmp.filter((a) => a);
      }
    } else {
      customAmounts =
        pricings && pricings.length
          ? [...pricings.filter((p) => p.custom).map((p) => p.amount)]
          : [];
    }

    if (customAmounts?.length > 0) {
      localStorage.setItem(
        product.id + ".customAmounts",
        JSON.stringify(customAmounts),
      );
    } else {
      if (product.id + ".customAmounts" in localStorage) {
        localStorage.removeItem(product.id + ".customAmounts");
      }
    }

    const calculationDTO = getCalculationDTO(customAmounts);
    apiDriver
      .post(
        `${user ? config.api.orders : config.api.products}${i18n.resolvedLanguage}/Products/${product.id}/Calculations`,
        calculationDTO,
      )
      .subscribe((r) => onFetchPricings(r, customAmounts));
    setUpdatePricings(false);
    setPricingLoaded(false);
    setAmount(0);
  };

  const onFetchPricings = (response, customAmounts) => {
    let data = response.response;
    setPricingLoaded(true);
    setPricings(
      data.map((d) => ({
        custom: customAmounts?.includes(d.key),
        amount: d.key,
        costNet: d.value.price,
        costGross: d.value.price * (1 + (product.taxRate || 0)),
        weight: d.value.weight,
        realizationTime: d.value.realizationTime,
        realizationTerm: d.value.realizationTerm,
        feedback: d.value.feedback,
        term: "NA",
      })),
    );
  };

  const getColorsDTO = () => {
    const dto = [...color]
      .filter((c) => c.color !== null && c.pages > 0)
      .map((c) => {
        let c2 = { ...c };
        if (c.name.endsWith(".inside")) {
          c2.pages = { inside: c.pages, outside: 0 };
        } else {
          c2.pages = { inside: 0, outside: c.pages };
        }
        return c2;
      });
    return dto;
  };

  const addToCart = () => {
    setAddingToCart(true);
    const dto = {
      productId: product.id,
      personalization: {
        amount: amount,
        format: { x: size.x * unitModifier, y: size.y * unitModifier },
        pages: {
          inside: pages.inside
            ? pages.inside
            : product?.productConfiguration?.pages?.default?.inside ?? 1,
          outside: pages.outside
            ? pages.outside
            : product?.productConfiguration?.pages?.default?.outside ?? 0,
        },
        options: options,
        additives: additives,
        project: project,
        checking: Checking.None,
        color: color.find((c) => c.name === "main.inside")?.color,
        colorProfiles: getColorsDTO(),
        realizationTimeType: realizationTimeType,
      },
    };
    dispatch(cartActions.putProduct(dto));
  };

  const changeRealizationTimeType = (type) => {
    setRealizationTimeType(type);
    setUpdatePricings(true);
  };

  const pricingTimerTrigger = () => {
    clearTimeout(pricingTimer);
    const newPricingTimer = setTimeout(() => {
      setUpdatePricings(true);
    }, 500);
    setPricingTimer(newPricingTimer);
  };
  const changeAmount = (e) => {
    setAmount(Number(e.target.value));
  };
  const changeSize = (e) => {
    if (e.target.name === "pool") {
      let tmp = e.target.value.split(",");
      setSize({ ...size, x: Number(tmp[0]), y: Number(tmp[1]) });
      setUpdatePricings(true);
    } else {
      const name = e.target.name;
      let value = Math.round(Number(e.target.value));
      if (value === "") {
        setSize({ ...size, [name]: value });
        return;
      }
      setSize({
        ...size,
        [name]: value * product.productConfiguration.format.unit,
      });
      pricingTimerTrigger();
    }
  };

  const changePages = (e) => {
    if (e.target.name === "pool") {
      let tmp = e.target.value.split(",");
      setPages({ ...pages, inside: Number(tmp[0]), outside: Number(tmp[1]) });
    } else {
      const name = e.target.name;
      let value = e.target.value;
      if (value === "") {
        setPages({ ...pages, [name]: value });
        return;
      }
      value = Math.round(Number(value));
      const min = product.productConfiguration.pages.minimal[name] || 0;
      const max =
        (e && e.target && e.target.max ? e.target.max : 0) ||
        product.productConfiguration.pages.maximal[name] ||
        1000000;
      if (value < min) value = min;
      if (value > max) value = max;
      const newPages = { ...pages, [name]: value };
      setPages(newPages);
      setColor(validatePagesOnColor(color, newPages));
      pricingTimerTrigger();
    }
  };

  const validatePagesOnColor = (colors, currentPages) => {
    colors = [...colors];

    let mainPages = currentPages || { ...pages };
    colors = colors
      .map((c) => {
        if (!c.name.startsWith("main.")) {
          if (c.pages < 0) {
            return { ...c, pages: 0 };
          }

          if (c.name.endsWith(".inside")) {
            if (c.pages >= pages.inside) {
              return { ...c, pages: 0 };
            }
            mainPages.inside -= c.pages;
          } else if (c.name.endsWith(".outside")) {
            if (c.pages >= pages.outside) {
              return { ...c, pages: 0 };
            }
            mainPages.outside -= c.pages;
          }
        }
        return c;
      })
      .map((c) => {
        if (c.name === "main.inside") {
          return { ...c, pages: mainPages.inside };
        } else if (c.name === "main.outside") {
          return { ...c, pages: mainPages.outside };
        }
        return c;
      });

    return colors;
  };

  const changeColor = (name, key, value) => {
    var changedColor = [...color].find((c) => c.name === name);
    changedColor = { ...changedColor, [key]: value };
    let newColor = [...color]
      .filter((c) => c.name !== name)
      .concat(changedColor);
    newColor = validatePagesOnColor(newColor, null);
    setColor(newColor);
    pricingTimerTrigger();
  };

  const changeColorBulk = (newColors) => {
    var newColor = [...color];
    for (var cc of newColors) {
      var changedColor = [...newColor].find((c) => c.name === cc.name);
      changedColor = { ...changedColor, ...cc };
      newColor = [...newColor]
        .filter((c) => c.name !== cc.name)
        .concat(changedColor);
    }
    newColor = validatePagesOnColor(newColor, null);
    setColor(newColor);
    pricingTimerTrigger();
  };

  const toggleProject = (e) => {
    setProject(e.target.checked);
    setUpdatePricings(true);
  };

  const updateColorsFromOption = (option, attribute) => {
    const isAffectingOnColor = {
      main: {
        inside: attribute.isAffectingOnColor & 1,
        outside: attribute.isAffectingOnColor & 2,
      },
      alt: {
        inside: attribute.isAffectingOnAlternateColor & 1,
        outside: attribute.isAffectingOnAlternateColor & 2,
      },
    };

    if (
      attribute.isAffectingOnColor ||
      attribute.isAffectingOnAlternateColor
    ) {
      let colorsToChange = [];

      if (isAffectingOnColor.main.inside) {
        colorsToChange.push({
          name: "main.inside",
          color: option.defineInsideColor,
        });
      }
      if (isAffectingOnColor.main.outside) {
        colorsToChange.push({
          name: "main.outside",
          color: option.defineOutsideColor,
        });
      }

      if (product.productConfiguration?.pages?.canDetermineColor) {
        if (isAffectingOnColor.alt.inside) {
          if (option.defineInsideAlternateColor !== null) {
            colorsToChange.push({
              name: "alt.inside",
              color: option.defineInsideAlternateColor,
            });
          } else if (
            option.defineInsideAlternateColor === null &&
            !product.productConfiguration?.pages?.canDetermineColorByUser
          ) {
            colorsToChange.push({
              name: "alt.inside",
              color: null,
            });
          }
        }
        if (isAffectingOnColor.alt.outside) {
          if (option.defineOutsideAlternateColor !== null) {
            colorsToChange.push({
              name: "alt.outside",
              color: option.defineOutsideAlternateColor,
            });
          } else if (
            option.defineOutsideAlternateColor === null &&
            !product.productConfiguration?.pages?.canDetermineColorByUser
          ) {
            colorsToChange.push({
              name: "alt.outside",
              color: null,
            });
          }
        }
      }

      return colorsToChange;
    }
    return [];
  }

  const updateColors = () => {
    let newColors = [];
    const selectedOptions = options.map(o => product.options.find((o2) => o2.id === o));
    selectedOptions.forEach(option => {
      const tmp = updateColorsFromOption(option, product.attributes.find((a) => a.id === option.attributeId));
      tmp.forEach(t => {
        newColors = [...newColors.filter(c => c.name !== t.name), t];
      });
    });

    let pagesForMain = { inside: 0, outside: 0 };

    newColors = newColors.map((col) => {
      const currentColor = color.find(c => c.name === col.name);
      if (currentColor) {
        if (!col.color && currentColor.pages > 0) {
          if (col.name.includes('.inside')) {
            pagesForMain.inside += currentColor.pages;
          } else {
            pagesForMain.outside += currentColor.pages;
          }
          return { ...col, pages: 0 };
        }
      }
      return col;
    });

    if (pagesForMain.inside > 0) {
      newColors = newColors.map(c => c.name === 'main.inside' ? { ...c, pages: c.pages + pagesForMain.inside } : c);
    }
    if (pagesForMain.outside > 0) {
      newColors = newColors.map(c => c.name === 'main.outside' ? { ...c, pages: c.pages + pagesForMain.outside } : c);
    }

    changeColorBulk(newColors);
  }

  const changeOption = (e) => {
    let optionId = Number(e.target.value);
    let attributeId = e.target.name.replace("attribute[", "").replace("]", "");
    const attribute = product.attributes.find((a) => a.id === attributeId);
    if (!options.some((o) => o === optionId)) {
      const option = attribute.options.find((o) => o.id === optionId);
      const optionsFromAttribute = attribute.options.map((o) => o.id);
      let optionsCopy = [...options].filter(
        (o) => !optionsFromAttribute.includes(o),
      );
      if (optionId) {
        optionsCopy.push(optionId);
      }
      setOptions(optionsCopy);
    }
  };
  const changeAdditive = (e) => {
    const additiveId = e.target.value;
    let additivesCopy = [...additives];
    const additiveGroupId = e.target.name;
    const additiveGroup = product.additiveGroups.find(
      (g) => g.id === additiveGroupId,
    );
    const additivesFromGroup = additiveGroup?.additives?.map((a) => a.id);

    if (additiveId === "") {
      additivesCopy = additivesCopy.filter(
        (a) => !additivesFromGroup.includes(a),
      );
    } else {
      switch (additiveGroup?.inputType) {
        case "Checkbox":
          if (additivesCopy.some((a) => a === additiveId)) {
            additivesCopy = additivesCopy.filter((a) => a !== additiveId);
          } else {
            additivesCopy.push(additiveId);
          }
          break;
        default:
          additivesCopy = additivesCopy.filter(
            (a) => !additivesFromGroup.includes(a),
          );
          additivesCopy.push(additiveId);
          break;
      }
    }
    setAdditives(additivesCopy);
  };
  const toggleAdditive = (e) => {
    const { checked, value } = e.target;
    let someResult = additives.some((a) => a === value);
    if (checked && !someResult) {
      let additivesCopy = [...additives];
      additivesCopy.push(value);
      setAdditives(additivesCopy);
      setUpdatePricings(true);
    } else if (!checked && someResult) {
      let additivesCopy = [...additives].filter((a) => a !== value);
      setAdditives(additivesCopy);
      setUpdatePricings(true);
    }
  };

  const onFetchProduct = (response) => {
    const data = response.response;
    data.currentTranslation = data.translations[0];
    data.attributes = data.attributes.map((attribute) => {
      attribute.options = attribute.options.map((option) => {
        option.currentTranslation = option.translations[0];
        return option;
      });
      attribute.currentTranslation = attribute.translations[0];
      return attribute;
    });
    data.additiveGroups = data.additiveGroups.map((additiveGroup) => {
      additiveGroup.options = additiveGroup.additives.map((additive) => {
        additive.currentTranslation = additive.translations[0];
        return additive;
      });
      additiveGroup.currentTranslation = additiveGroup.translations[0];
      return additiveGroup;
    });
    data.additives = data.additives.map((additive) => {
      additive.currentTranslation = additive.translations[0];
      return additive;
    });
    setAdditives(
      data.additiveGroups.map(
        (group) =>
          group.additives
            .map((a) => ({
              ...a,
              order: data.productAdditives.find((a2) => a2.additiveId === a.id)
                .order,
            }))
            .sort((a, b) => b.order - a.order)[0].id,
      ),
    );
    setOptions(
      data.attributes
        .filter(
          (attribute) => attribute.isRequired || attribute.isAffectingOnPrice,
        )
        .map(
          (attribute) =>
            attribute.options
              .map((o) => ({
                ...o,
                order: data.productOptions.find((o2) => o2.optionId === o.id)
                  .order,
              }))
              .sort((a, b) => b.order - a.order)[0].id,
        ),
    );
    setProduct(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!pricings.some((p) => p.amount === amount)) {
      setAmount(0);
    }
  }, [amount, pricings]);

  useEffect(() => {
    if (!product?.options) {
      return;
    }
    fetchPricing();
  }, [options, additives]);

  useEffect(() => {
    if (!product?.options) {
      return;
    }
    updateColors();
  }, [options]);

  useEffect(() => {
    if (!i18n.resolvedLanguage) {
      return;
    }
    const url = `${config.api.products}${i18n.resolvedLanguage}/Products/${product?.id || id}/?full=true`;
    apiDriver.get(url).subscribe({
      next: (response) => onFetchProduct(response),
      error: (e) => console.error(e),
    });
  }, [i18n, i18n?.resolvedLanguage]);

  useEffect(() => {
    if (!product || !product.productConfiguration) {
      return;
    }

    console.log(product?.productConfiguration?.realizationTimes);
    if (!product?.productConfiguration?.realizationTimes?.map(s => s.type).includes("Standard")) {
      console.log('not includes', product?.productConfiguration?.realizationTimes[0], product?.productConfiguration?.realizationTimes[0].type);
      setRealizationTimeType(product?.productConfiguration?.realizationTimes[0].type);
    }

    if (
      product.productConfiguration?.pages !== null ||
      product.productConfiguration?.pages?.type !== "Const"
    ) {
      setPages(
        product?.productConfiguration?.pages?.default ?? {
          inside: 1,
          outside: 0,
        },
      );
    }

    if (color?.length === 0) {
      let colors = [
        {
          name: "main.inside",
          color: properties.insideColor || 0,
          pages: pages.inside || 0,
          order: 1,
        },
        {
          name: "main.outside",
          color: properties.outsideColor || 0,
          pages: pages.outside || 0,
          order: 1,
        },
      ];
      if (product.productConfiguration?.pages?.canDetermineColor) {
        colors.push({
          name: "alt.inside",
          color: properties.insideAlternateColor || null,
          pages: 0,
          order: 0,
        });
        colors.push({
          name: "alt.outside",
          color: properties.outsideAlternateColor || null,
          pages: 0,
          order: 0,
        });
      }
      setColor(
        validatePagesOnColor(
          colors,
          product?.productConfiguration?.pages?.default ?? {
            inside: 1,
            outside: 0,
          },
        ),
      );
    }

    if (product?.id) {
      setUpdatePricings(true);
    }
  }, [product]);

  useEffect(() => {
    if (updatePricings) {
      fetchPricing();
    }
  }, [updatePricings]);

  useEffect(() => {
    setAddingToCart(false);
  }, [cart]);

  if (!product || !product.id) {
    return (
      <div className="text-center m-5 p-5">
        <Spinner animation="border" role="status">
          {t("common.loading")}
        </Spinner>
      </div>
    );
  }

  let properties = {
    thickness: { inside: 0, outside: 0 },
    grammage: { inside: 0, outside: 0 },
    sheetPrice: { inside: 0, outside: 0 },
    sheetPriceType: { inside: 0, outside: 0 },
    color: 0,
  };
  let selectedOptions = options.map((option) =>
    product.options.find((o) => o.id === option),
  );
  selectedOptions.forEach((option) => {
    const attribute = product.attributes.find(
      (a) => a.id === option.attributeId,
    );

    if (attribute.isAffectingOnThickness) {
      if (attribute.isAffectingOnThickness & 1) {
        properties.thickness.inside = option.defineInsideThickness;
      }
      if (attribute.isAffectingOnThickness & 2) {
        properties.thickness.outside = option.defineOutsideThickness;
      }
    }

    if (attribute.isAffectingOnGrammage) {
      if (attribute.isAffectingOnGrammage & 1) {
        properties.grammage.inside = option.defineInsideGrammage;
      }
      if (attribute.isAffectingOnGrammage & 2) {
        properties.grammage.outside = option.defineOutsideGrammage;
      }
    }

    if (attribute.isAffectingOnSheetPrice) {
      if (attribute.isAffectingOnSheetPrice & 1) {
        properties.sheetPrice.inside = option.defineInsideSheetPrice;
        properties.sheetPriceType.inside = option.defineInsideSheetPriceType;
      }
      if (attribute.isAffectingOnSheetPrice & 2) {
        properties.sheetPrice.outside = option.defineOutsideSheetPrice;
        properties.sheetPriceType.outside = option.defineOutsideSheetPriceType;
      }
    }
    if (option.defineInsideColor)
      properties.insideColor = option.defineInsideColor;
    if (option.defineOutsideColor)
      properties.outsideColor = option.defineOutsideColor;
    if (option.defineInsideAlternateColor)
      properties.insideAlternateColor = option.defineInsideAlternateColor;
    if (option.defineOutsideAlternateColor)
      properties.outsideAlternateColor = option.defineOutsideAlternateColor;
  });

  return (
    <>
      <Helmet>
        <title>{product.currentTranslation?.title} - Printweb.pl</title>
      </Helmet>
      <div className="wrapper" style={{ position: 'relative' }}>
        <div className="cd-section">
          <Container fluid>
            <Row>
              <Col lg={12} xl={10} className="offset-xl-1">
                <h3 className="display-3">
                  {product.currentTranslation?.title}
                </h3>
              </Col>
              <Col lg={6} xl={5} className="offset-xl-1">
                {product.productConfiguration &&
                  product.productConfiguration.format &&
                  (product.productConfiguration.format.defined ||
                    product.productConfiguration.format.canUseCustom) ? (
                  <React.Fragment>
                    <h4 className="text-uppercase">
                      {t("products.fields.format")}
                      {product.currentTranslation?.hintFormat ? (
                        <>
                          <small
                            style={{
                              fontSize: "50%",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            <i
                              className="text-primary fas fa-question-circle ml-1"
                              id={`tooltipFormat${product.id}`}
                            ></i>
                          </small>
                          <Tooltip
                            placement="right"
                            isOpen={isTooltipFormatOpen}
                            target={`tooltipFormat${product.id}`}
                            toggle={toggleTooltipFormat}
                          >
                            {product.currentTranslation?.hintFormat}
                          </Tooltip>
                        </>
                      ) : (
                        <React.Fragment />
                      )}
                    </h4>
                    <Card>
                      <CardBody className="py-3">
                        <Format
                          formats={product.printFormats}
                          configuration={product.productConfiguration.format}
                          size={size}
                          changeSize={changeSize}
                        />
                      </CardBody>
                    </Card>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {product.attributes.length > 0 ||
                  product.additiveGroups.length > 0 ||
                  product.additives.length > 0 ? (
                  <React.Fragment>
                    {product.attributes
                      .map((attribute) => {
                        const attributeOptions = attribute.options.map(
                          (o) => o.id,
                        );
                        const attributeProductOptionsOrders =
                          product.productOptions
                            .filter((po) =>
                              attributeOptions.includes(po.optionId),
                            )
                            .map((po) => po.order || 0) || [0];
                        const obj = {
                          attribute: attribute,
                          order: Math.max(...attributeProductOptionsOrders),
                        };
                        return obj;
                      })
                      .sort((a, b) => b.order - a.order)
                      .map((attribute) => attribute.attribute)
                      .map((attribute) => (
                        <Attribute
                          key={attribute.id}
                          attribute={attribute}
                          options={options}
                          productOptions={product.productOptions}
                          conflictedOptions={conflictedOptions}
                          onChange={changeOption}
                        />
                      ))}
                    {product.additiveGroups
                      .map((additiveGroup) => {
                        const groupAdditives = additiveGroup.additives.map(
                          (a) => a.id,
                        );
                        const additiveGroupProductOrders =
                          product.productAdditives
                            .filter((pa) =>
                              groupAdditives.includes(pa.additiveId),
                            )
                            .map((pa) => pa.order || 0) || [0];
                        const obj = {
                          additiveGroup: additiveGroup,
                          order: Math.max(...additiveGroupProductOrders),
                        };
                        return obj;
                      })
                      .sort((a, b) => b.order - a.order)
                      .map((additiveGroup) => additiveGroup.additiveGroup)
                      .map((additiveGroup) => (
                        <AdditiveGroup
                          key={additiveGroup.id}
                          additiveGroup={additiveGroup}
                          conflictedAdditives={conflictedAdditives}
                          additives={additives}
                          productAdditives={product.productAdditives}
                          onToggle={toggleAdditive}
                          onChange={changeAdditive}
                        />
                      ))}
                    {product.additives
                      .filter((additive) => additive.GroupId === null)
                      .filter(
                        (additive) =>
                          !conflictedAdditives.includes(additive.id),
                      )
                      .map((additive) => (
                        <Additive
                          key={additive.id}
                          additive={additive}
                          additives={additives}
                          onToggle={toggleAdditive}
                          onChange={changeAdditive}
                        />
                      ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {product?.productConfiguration?.pages?.type !== "Const" ? (
                  <React.Fragment>
                    <h4 className="text-uppercase">
                      {t("products.fields.pages")}
                      {product.currentTranslation?.hintPaging ? (
                        <>
                          <small
                            style={{
                              fontSize: "50%",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            <i
                              className="text-primary fas fa-question-circle ml-1"
                              id={`tooltipPaging${product.id}`}
                            ></i>
                          </small>
                          <Tooltip
                            placement="right"
                            isOpen={isTooltipPagingOpen}
                            target={`tooltipPaging${product.id}`}
                            toggle={toggleTooltipPaging}
                          >
                            {product.currentTranslation?.hintPaging}
                          </Tooltip>
                        </>
                      ) : (
                        <React.Fragment />
                      )}
                    </h4>
                    <Card>
                      <CardBody>
                        <Pages
                          configuration={product.productConfiguration.pages}
                          properties={properties}
                          pages={pages}
                          changePages={changePages}
                          color={color}
                          changeColor={changeColor}
                        />
                      </CardBody>
                    </Card>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {product?.productConfiguration?.project != null ? (
                  <React.Fragment>
                    <h4 className="text-uppercase">
                      {t("products.fields.project")}
                      {product.currentTranslation?.hintProject ? (
                        <>
                          <small
                            style={{
                              fontSize: "50%",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            <i
                              className="text-primary fas fa-question-circle ml-1"
                              id={`tooltipProject${product.id}`}
                            ></i>
                          </small>
                          <Tooltip
                            placement="right"
                            isOpen={isTooltipProjectOpen}
                            target={`tooltipProject${product.id}`}
                            toggle={toggleTooltipProject}
                          >
                            {product.currentTranslation?.hintProject}
                          </Tooltip>
                        </>
                      ) : (
                        <React.Fragment />
                      )}
                    </h4>
                    <Project
                      configuration={product.productConfiguration.project}
                      project={project}
                      pages={pages}
                      toggleProject={toggleProject}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {
                  product?.productConfiguration?.mockupTypes?.length > 0 &&
                  <>
                    <h4 className="text-uppercase">
                      {t("products.schemasAndMockups")}
                    </h4>
                    <Card>
                      <CardBody>
                        <Schema
                          product={product}
                          getCalculationDTO={getCalculationDTO}
                          size={size}
                          updatePricings={updatePricings}
                        />
                        <Template />
                      </CardBody>
                    </Card>
                  </>
                }
                <p className="mt-1">
                  {ReactHtmlParser(product.currentTranslation?.descriptionSidebar)}
                </p>
              </Col>
              <Col lg={6} xl={5}>
                <h4 className="text-uppercase">
                  {product.currentTranslation?.metaDescription}
                </h4>
                <p className="mt-1">
                  {ReactHtmlParser(product.currentTranslation?.descriptionHeader)}
                </p>
                <h4 className="text-uppercase">{t("products.selectAmount")}</h4>
                <RebateInfo product={product} />
                <RealizationTimes
                  pricings={pricings}
                  setRealizationTimeType={changeRealizationTimeType}
                  realizationTimeType={realizationTimeType}
                  realizationTimes={realizationTimes}
                />
                {product.productConfiguration &&
                  product.productConfiguration.amount ? (
                  <PricingWrapper
                    pricingLoaded={pricingLoaded}
                    pricings={pricings}
                    amount={amount}
                    changeAmount={changeAmount}
                    canUseCustom={
                      product.productConfiguration &&
                      product.productConfiguration.amount &&
                      product.productConfiguration.amount.canUseCustom
                    }
                    addCustomPricing={addCustomPricing}
                    removeCustomPricing={removeCustomPricing}
                    min={product.productConfiguration.amount.minimal || 1}
                    max={
                      product.productConfiguration.amount.maximal || 100000000
                    }
                  />
                ) : (
                  <React.Fragment />
                )}
                <p className="mt-1">
                  {ReactHtmlParser(product.currentTranslation?.descriptionFooter)}
                </p>
              </Col>
            </Row>
          </Container>
          {!pricingLoaded && <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '#fff', opacity: 0.75 }}></div>}
        </div>
        {amount !== 0 && pricings.some((p) => p.amount === amount) ? (
          <Summary
            pricing={pricings.find((p) => p.amount === amount)}
            addToCart={addToCart}
            addingToCart={addingToCart}
          />
        ) : (
          <React.Fragment />
        )}
        <div className="my-5"></div>
      </div>
    </>
  );
}

export default Product;
