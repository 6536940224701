import React from 'react';
import { Alert, Input, Row, Col, Form, Button, Spinner, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TabDelete = () => {
  const { t } = useTranslation();
  return (
    <div tabId="delete">
      <header>
        <h2 className="text-uppercase">{t("users.delete.title")}</h2>
      </header>
      <hr className="line-primary"></hr>
      <br></br>
      <Form>
        <div>
          <div className="text-center">
            <i className="fas fa-trash fa-5x text-muted m-5"></i>
          </div>
          <Alert color="success">{t("users.delete.success")}</Alert>
          <Alert color="danger">{t("auth.errors.incorrectPassword")}</Alert>
          <Alert color="danger">{t("errors.common.title")}</Alert>
          <Alert color="info">
            <p>
              <b>{t("users.delete.header")}</b>
            </p>
            <p>{t("users.delete.content")}</p>
          </Alert>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("users.fields.password")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  id="name"
                  name="name"
                  required="required"
                  placeholder={t("users.fields.password")}
                  type="password"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-right">
              <Button color="primary" type="submit">
                {t("users.actions.delete")}
              </Button>
              <Button color="primary" outline type="reset">
                {t("actions.cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TabDelete;