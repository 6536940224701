import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Form, Spinner, Button, Row, Col } from 'reactstrap';
import Order from "views/Orders/Order";

const OrdersList = (props) => {
  const { title, statuses } = props;
  const { t, i18n } = useTranslation();
  const [skip, setSkip] = useState(0);
  const [orders, setOrders] = useState(undefined);
  const [business, setBusiness] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [isNoMoreResults, setNoMoreResults] = useState(false);
  const take = 5;

  const reload = (orderId) => {
    setFetching(true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/Orders/${orderId}`,
      )
      .subscribe({
        next: onFetchOrder,
        error: console.error,
        complete: () => setFetching(false)
      });
  }

  const onFetchOrderProduct = (response) => {
    const orderProduct = response.response;
    setOrders([
      ...orders.map((o) => {
        if (o.id !== orderProduct.orderId) return o;
        return {
          ...o,
          products: [...o.products].map((p) => {
            if (p.id !== orderProduct.id) return p;
            return { ...orderProduct, loaded: true };
          }),
        };
      }),
    ]);
  };

  const fetchOrderProduct = (orderProductId) => {
    setFetching(true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProductId}`,
      )
      .subscribe({
        next: onFetchOrderProduct,
        error: console.error,
        complete: () => setFetching(false)
      });
  }

  const onFetchOrders = (response) => {
    const fetchedOrders = response.response;

    setOrders([
      ...(orders || []),
      ...fetchedOrders.map((o) => ({
        ...o,
        notes: null,
        products: [...o.products].map((p) => ({ ...p, loaded: false })),
      })),
    ]);
    setSkip(skip + fetchedOrders?.length);
    setNoMoreResults(fetchedOrders?.length < take);
  }

  const onFetchOrder = (response) => {
    const fetchedOrder = response.response;
    setOrders([...orders].map((o) => {
      if (o.id !== fetchedOrder.id) return o;
      return {
        ...fetchedOrder,
        notes: null,
        products: [...fetchedOrder.products].map((p) => ({ ...p, loaded: false })),
      }
    }));
  }

  const fetchOrders = () => {
    setFetching(true);
    const attributes = apiDriver.buildIndexAttributes({
      skip: skip,
      take: take,
      order: { field: "created", direction: "DESC" },
    });
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/Orders/${attributes}&Status=${statuses}${business ? "&Business=True" : ""}`,
      )
      .subscribe({
        next: onFetchOrders,
        error: console.error,
        complete: () => setFetching(false)
      });
  };

  useEffect(() => {
    if (!i18n.resolvedLanguage) return;
    if (isFetching) return;
    if (orders === undefined) {
      fetchOrders();
      return;
    }
    if (orders?.some(o => o.products && o.products.some((p) => p.loaded === false))) {
      const order = orders?.find(o => o.products && o.products.some((p) => p.loaded === false));
      const product = order.products.find((p) => p.loaded === false);
      fetchOrderProduct(product.id);
      return;
    }
  }, [isFetching]);

  useEffect(() => {
    setOrders(undefined);
    setFetching(false);
    fetchOrders();
  }, [i18n?.resolvedLanguage])

  return (
    <div tabId="current">
      <Form>
        <div>
          <header>
            <h2 className="text-uppercase">{title}</h2>
          </header>
          <hr className="line-primary"></hr>
          <br></br>
          <div className="clearfix">
            <div className="float-left">
              <h4>{t("order.orders")}</h4>
            </div>
            <div className="float-left">
              {isFetching.length > 0 ? (
                <Spinner className="ml-3" />
              ) : (
                <React.Fragment />
              )}
            </div>
            <div className="float-right ml-3 pt-1 d-none">
              <i className="fas fa-user fa-2x"></i>
              <label className="custom-toggle mx-3">
                <input
                  type="checkbox"
                  checked={business}
                  onChange={(event) => setBusiness(event.target.checked)}
                  disabled={isFetching.length > 0}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <i className="fas fa-suitcase fa-2x"></i>
            </div>
            <div className="float-right d-none">
              <Button color="dark" outline type="button">
                {t("order.filter")} &nbsp; <i className="fas fa-filter"></i>
              </Button>
            </div>
          </div>
          <hr />
          {orders?.map((order) => (
            <Order
              key={order.id}
              order={order}
              business={business}
              reload={() => reload(order.id)}
            />
          ))}
        </div>
        <Row>
          <Col md={6}>
            {t("order.orders")}: {orders?.length}
          </Col>
          <Col md={6} className="text-right">
            <Button
              onClick={fetchOrders}
              disabled={isFetching || isNoMoreResults}
            >
              {t("actions.loadMore")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default OrdersList;