import RealizationTerm from "components/Cart/RealizationTerm";
import React from "react";
import { useTranslation } from "react-i18next";

function Colors() {
  return [
    "1+0",
    "1+1",
    "4+0",
    "4+1",
    "4+4",
    "2+0",
    "2+1",
    "5+0",
    "5+1",
    "5+4",
    "5+5",
  ];
}

export default function ProductPersonalization(props) {
  const { t, i18n } = useTranslation();
  const { product, full, includePrices, includeWeight, includeRealizationTerm } = props;

  const getPersonalization = () => {
    let result = [];

    if (full) {
      result.push(`
        ${t("products.fields.amount")}:
        ${new Intl.NumberFormat(i18n.resolvedLanguage, { maximumFractionDigits: 0, }).format(product?.personalization?.amount)}
        ${t('price.piece')}
      `);
    }

    if (product?.personalization?.format) {
      const format = product.personalization.format;
      result.push(
        `${t("products.fields.format")}: ${format.x} x ${format.y} mm`,
      );
    }
    if (product?.personalization?.pages) {
      const pages = product.personalization.pages;
      if (pages.inside <= 2 && pages.outside === 0) {
        // do nothing
      } else if (pages.inside && pages.outside) {
        result.push(
          `${t("products.pages.header")}: ${pages.inside} ${t(
            "products.pages.inside",
          )}, ${pages.outside} ${t("products.pages.outside")}`,
        );
      } else if (pages.inside > 1) {
        result.push(
          `${t("products.pages.header")}: ${pages.inside} ${t(
            "products.pages.inside",
          )}`,
        );
      } else if (pages.outside) {
        result.push(
          `${t("products.pages.header")}: ${pages.outside} ${t(
            "products.pages.outside",
          )}`,
        );
      }
    }
    if (product?.personalization?.optionsLoaded) {
      product.personalization.optionsLoaded.map((option) => {
        const optionTranslation = option.translations[0];
        const attribute = option.attribute;
        const attributeTranslation = attribute.translations[0];
        result.push(
          `${attributeTranslation?.title}: ${optionTranslation?.title}`,
        );
        return option;
      });
    }
    if (product?.personalization?.additivesLoaded) {
      product.personalization.additivesLoaded.map((additive) => {
        const additiveTranslation = additive.translations[0];
        const group = additive.group;
        if (group) {
          const groupTranslation = group.translations[0];
          result.push(
            `${groupTranslation?.title}: ${additiveTranslation?.title}`,
          );
        } else {
          result.push(additiveTranslation?.title);
        }
        return additive;
      });
    }
    if (product?.personalization?.colorProfiles?.length > 1) {
      const colorProfiles = product.personalization.colorProfiles
      const altColorProfiles = colorProfiles.filter(cp => cp.name.includes('alt.'));
      if (altColorProfiles.length) {
        result = [...result, ...colorProfiles.map(cp => {
          if (cp.pages.inside > 0) return `${Colors()[cp.color]}: ${cp.pages.inside} ${t("products.pages.header").toLowerCase()} ${t("products.pages.inside")}`
          if (cp.pages.outside > 0) return `${Colors()[cp.color]}: ${cp.pages.outside} ${t("products.pages.header").toLowerCase()} ${t("products.pages.outside")}`
        })];
      }
    }

    if (full) {
      result.push(`
        ${t("order.checking.checking")}:
        ${t(`order.checking.${product?.personalization?.checking?.toLowerCase()}.title`)}
      `);
    }

    if (includeWeight) {
      result.push(`
        ${t("products.fields.weight")}:
        ${product.weight} kg
      `);
    }

    if (includeRealizationTerm) {
      result.push(`
        ${t("products.fields.realizationTerm")}:
        ${RealizationTerm({ term: product.realizationTerm })}
      `);
    }

    if (includePrices) {
      result.push(`
        ${t("price.price.net")}:
        ${new Intl.NumberFormat(i18n.resolvedLanguage, {
        style: "currency",
        currency: "PLN",
      }).format(product.priceObj.netCost)}
      `);
      result.push(`
        ${t("price.price.gross")}:
        ${new Intl.NumberFormat(i18n.resolvedLanguage, {
        style: "currency",
        currency: "PLN",
      }).format(product.priceObj.grossCost)}
      `);
    }

    return result.filter(r => r).map((r, i) => (
      <React.Fragment key={i}>
        {r}
        <br />
      </React.Fragment>
    ));
  };

  return <span>{product?.personalization && getPersonalization()}</span>;
}
