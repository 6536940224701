import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import config from "config/global";
import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";
import LocalizedLink from "components/LocalizedLink";

export default function PageLink(props) {
  const { i18n } = useTranslation();
  const { id, url } = props;

  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);  // eslint-disable-line no-unused-vars
  const [error, setError] = useState(false); // eslint-disable-line no-unused-vars

  const loadPage = () => {
    setLoading(true);
    setError(false);
    apiDriver
      .get(`${config.api.cms}${i18n.resolvedLanguage}/Pages/${id || url}`)
      .subscribe({
        next: (r) => setPage(r.response),
        error: () => setError(true),
        complete: () => setLoading(false),
      });
  };

  useEffect(() => {
    if (!i18n.resolvedLanguage) {
      return;
    }
    loadPage();
  }, [id, url, i18n, i18n?.resolvedLanguage]);

  if (!url && !id) {
    return (
      <>
        <Alert color="danger">No id, url provided for PageEmbed</Alert>
      </>
    );
  }

  return (
    <LocalizedLink to={`/p/${page?.translations[0]?.url}`} {...props?.linkProps}>
      {page?.translations[0]?.title || page?.id}
    </LocalizedLink>
  );
}
