import MainNavbar from 'components/navbars/Navbar';
import React, { useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';

export default function Error404({ error, resetErrorBoundary }) {

  const testingEnvironments = [
    'localhost',
    'qa.printweb.pl',
    'uat.printweb.pl',
    'printweb-qa-client.h0pe.cloud',
    'printweb-uat-client.h0pe.cloud'
  ];

  const isTestingEnvironment = testingEnvironments.includes(window.location.hostname);

  useEffect(() => {
    if (!isTestingEnvironment) {
      setTimeout(reset, 2500);
    }
  }, []);

  const redirect = () => window.location.href = '/';

  const reset = () => {
    resetErrorBoundary();
    redirect();
  }

  return (
    <>
      <Container className="pt-5 vh-100">
        <div className="clearfix my-5 py-5">
          <h1 className="text-muted text-center my-5 py-5" style={{ fontSize: '1200%' }}>Oops!</h1>
          <h3 className="text-center my-5 py-5">404 - Document not found</h3>
        </div>
      </Container>
    </>
  )
}