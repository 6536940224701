import React, { useEffect, useState } from "react";
import config from "config/global";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Container, Row, Col, Spinner } from "reactstrap";

import "./Products.css";

// Core Components
import Category from "./Category";
import apiDriver from "stores/api.driver";

function Products() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1,
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });

  const fetchProductsBase = () => {
    apiDriver.get(`${config.api.products}${i18n.resolvedLanguage}/Categories/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .subscribe({
        next: (response) => {
          setCategories(response.response);
          setProductsLoaded(true);
        }
      })
    apiDriver.get(`${config.api.products}${i18n.resolvedLanguage}/Products/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .subscribe({
        next: (response) => {
          setProducts(response.response);
          setProductsLoaded(true);
        }
      })
  };

  useEffect(() => {
    if (i18n?.resolvedLanguage) {
      fetchProductsBase();
    }
  }, [i18n?.resolvedLanguage]);

  if (!productsLoaded) {
    return (
      <>
        <Helmet>
          <title>{t("products.list")} - Printweb.pl</title>
        </Helmet>
        <div className="text-center m-5 p-5">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("products.list")} - Printweb.pl</title>
      </Helmet>
      <Container
        fluid
        className="shape-container d-flex align-items-center py-0 py-lg-5 px-1 px-md-4"
      >
        <div className="col px-0">
          <h3 className="display-3 d-none d-md-block">{t("products.ourProducts")}</h3>
          <p className="lead mt-1 d-none d-md-block">{t("products.ourProductsSlogan")}</p>
          {categories.sort((a, b) => b.order - a.order).map((category) => (
            <Category
              key={category.id}
              category={category}
              products={[...products].filter(
                (product) => product.categoryId === category.id,
              )}
            />
          ))}
        </div>
      </Container>
    </>
  );
}

export default Products;
