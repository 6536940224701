import CountrySelector from "components/AddressForm/CountrySelector";
import PostalCodeInput from "components/AddressForm/PostalCodeInput";
import VatIDInput from "components/AddressForm/VatIDInput";
import config from "config/global";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import apiDriver from "stores/api.driver";
import { Countries } from "views/Auth/Register";

function BillingInfoForm(props) {
  const { t, i18n } = useTranslation();
  const { billingInfo, onChange, setBillingInfo, readOnly } = props;

  const currentCountry = Countries().find(
    (country) => country.code === billingInfo?.countryCode,
  );

  const getFromGus = () => {
    const vatID = billingInfo?.vatID;
    console.log(vatID);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/Gus/${vatID.replace('PL', '')}`)
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            const result = { ...response.response };
            delete result.id;
            setBillingInfo({ ...billingInfo, ...result });
          } else {
            alert(t("auth.validation.invalidVatId"));
          }
        },
        error: alert
      });
  };

  return (
    <Row>
      <Col md="12">
        <FormGroup>
          <Input
            id="buyerName"
            name="buyerName"
            placeholder={t("customers.fields.businessName")}
            type="text"
            value={billingInfo?.buyerName}
            onChange={(e) => onChange(e)}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <InputGroup className=" mb-4">
            <VatIDInput
              countryCode={billingInfo?.countryCode}
              id="vatID"
              name="vatID"
              placeholder={t("customers.fields.vatID")}
              type="text"
              value={billingInfo?.vatID}
              onChange={(e) => onChange(e)}
              readOnly={readOnly}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                outline
                type="button"
                title={t("customers.billingInfo.getFromGUS")}
                onClick={getFromGus}
                disabled={billingInfo?.countryCode !== 'pl' || !(billingInfo?.vatID?.length === 10 || (billingInfo?.vatID?.length === 12 && billingInfo?.vatID?.includes('PL')))}
              >
                <i className="fas fa-search"></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="line1"
            name="line1"
            placeholder={t("customers.shippingAddress.line1")}
            type="text"
            value={billingInfo?.line1}
            onChange={(e) => onChange(e)}
            required={true}
            minLength={3}
            maxLength={64}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="line2"
            name="line2"
            placeholder={t("customers.shippingAddress.line2")}
            type="text"
            value={billingInfo?.line2}
            onChange={(e) => onChange(e)}
            minLength={3}
            maxLength={64}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <PostalCodeInput
            countryCode={billingInfo?.countryCode}
            id="postalCode"
            name="postalCode"
            type="text"
            value={billingInfo?.postalCode}
            onChange={(e) => onChange(e)}
            required={true}
            minLength={3}
            maxLength={16}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="city"
            name="city"
            placeholder={t("customers.shippingAddress.city")}
            type="text"
            value={billingInfo?.city}
            onChange={(e) => onChange(e)}
            required={true}
            minLength={3}
            maxLength={64}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <CountrySelector
            value={billingInfo?.countryCode}
            onChange={onChange}
            required={true}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="email"
            name="email"
            placeholder={t("customers.shippingAddress.email")}
            type="text"
            value={billingInfo?.email}
            onChange={(e) => onChange(e)}
            readOnly={readOnly}
            minLength={3}
            maxLength={64}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
export default BillingInfoForm;
