import React, { useState, useEffect } from "react";

import { Col, Row, Spinner, Card, CardBody } from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import BillingInfoSelector from "./BillingInfoSelector";
import PaymentMethod from "./PaymentMethod";
import BillingInfoForm from "./BillingInfoForm";
import { useTranslation } from "react-i18next";

function CartBilling(props) {
  const { t, i18n } = useTranslation();
  const {
    cart,
    invoice,
    shippingMethod,
    setInvoice,
    billingInfo,
    setBillingInfo,
    setBillingInfoData,
    selectedMethod,
    setMethod,
  } = props;

  const [userBillingInfos, setUserBillingInfos] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);

  const [areUserBillingInfoLoading, setUserBillingInfoLoading] = useState(false);

  const addUserBillingInfo = (data) =>
    setUserBillingInfos([...userBillingInfos, data]);

  const loadUserBillingInfo = (searchTerm) => {
    setUserBillingInfoLoading(true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/BillingInfos${apiDriver.buildIndexAttributes({take: 4, searchText: searchTerm})}`)
      .subscribe({
        next: (response) => {
          const info = [...response.response];
          if(cart.billingInfo && !info.some(a => a.id === cart.billingInfo.id)) {
            info.push(cart.billingInfo);
          }
          setUserBillingInfos(info);
        },
        error: alert,
        complete: () => setUserBillingInfoLoading(false)
      });
  }

  const loadPaymentMethods = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/PaymentMethods`;
    apiDriver
      .get(url)
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        setPaymentMethods(data);
      });
  };

  useEffect(() => {
    if (paymentMethods === null) {
      loadPaymentMethods();
    }
  }, [paymentMethods]);

  useEffect(
    () => {
      if (userBillingInfos === null) {
        setUserBillingInfos([]);
        if (cart.userId) {
          loadUserBillingInfo();
        }
      }
    },
    [userBillingInfos],
  );

  useEffect(() => {
    if (!cart.userId) {
      if (cart.billingInfo) {
        setBillingInfo(cart.billingInfo);
      } else {
        setBillingInfo("new");
      }
    }
  }, [cart.userId]);

  if (!paymentMethods) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  if (userBillingInfos === null) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <h2 className="text-uppercase">{t("customers.billingInfo.billingInfo")}</h2>
      <Row>
        <Col md={4}>
          <h3>{t("order.payment.method")}</h3>
          {paymentMethods.filter(m => m.isRequiredToProcessOrder == shippingMethod?.isPaymentRequiredToProcessOrder)
            .map((method) => (
              <PaymentMethod
                key={method.id}
                method={method}
                selectedMethod={selectedMethod}
                setMethod={setMethod}
              />
          ))}
        </Col>
        <Col md={4}>
          <h3>{t("cart.invoicing.invoicing")}</h3>
          <Row className="text-center">
            <Col sm={6} className="text-center">
              <Card
                className={
                  !invoice
                    ? "border border-primary text-primary"
                    : "border text-muted"
                }
                style={{ cursor: "pointer" }}
                onClick={() => setInvoice(false)}
              >
                <CardBody className="px-3 py-3">
                  <i className="fas fa-receipt fa-4x"></i>
                  <br />
                  {t("cart.invoicing.receipt")}
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card
                className={
                  invoice
                    ? "border border-primary text-primary"
                    : "border text-muted"
                }
                style={{ cursor: "pointer" }}
                onClick={() => setInvoice(true)}
              >
                <CardBody className="px-3 py-3">
                  <i className="fas fa-file-invoice fa-4x"></i>
                  <br />
                  {t("cart.invoicing.invoice")}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <h3 className={invoice ? "" : "text-muted"}>
            {t("customers.billingInfo.billingInfo")} {areUserBillingInfoLoading && <Spinner />}
          </h3>
          {invoice ? (
            <>
              {cart.userId ? (
                <>
                  <BillingInfoSelector
                    infos={userBillingInfos}
                    currentInfo={cart.billingInfo?.id}
                    setInfo={setBillingInfo}
                    canCreate={true}
                    showNone={false}
                    billingInfo={billingInfo}
                    setBillingInfo={setBillingInfoData}
                    onAddInfo={addUserBillingInfo}
                    onSearch={loadUserBillingInfo}
                  />
                </>
              ) : (
                <BillingInfoForm
                  billingInfo={billingInfo}
                  onChange={(e) => setBillingInfoData(e.target.name, e.target.value)}
                  readOnly={false}
                  setBillingInfo={setBillingInfo}
                />
              )}
            </>
          ) : (
            <React.Fragment />
          )}
        </Col>
      </Row>
      <hr className="my-3 p-0 w-100" />
    </>
  );
}
export default CartBilling;
